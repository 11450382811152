import React from "react"
import { Link } from "gatsby"
import { Helmet } from 'react-helmet';
import Layout from "../templates/layout"
import SEO from "../components/seo/seo"
import Hero from "../components/hero/hero"
import Card from "../components/card/card"
import LinkCard from "../components/linkCard/linkCard"
import HaveQuestions from "../components/haveQuestions/haveQuestions"
import BrandLogo from "../images/logos/elitek-logo.svg"
import QuestionsIcon from "../images/icons/icon-questions-elitek.svg"
// import EnrollIcon from "../images/icons/icon-enroll-elitek.svg"
import enrollmentIcon from "../images/icons/icon-enroll-elitek-hero.png"
import BackToTop from "../components/backToTop/BackToTop";
import RightArrow from "../images/icons/right-arrow-icon.svg"
import AffordIcon from "../images/icons/icon-financial-elitek-hero.png";
import FAQIcon from "../images/icons/icon-faq-elitek.png"

const pageContent = () => (

    <Layout pageid="page-home-elitek">

      <Helmet
          bodyAttributes={{
              class: 'new-class-for-body'
          }}
      />
      <SEO
      title="CareASSIST for patients prescribed ELITEK® (rasburicase) | Patient Site"
      keywords="CareASSIST, Sanofi, ELITEK® (rasburicase), Patient Site"
      description="Info for patients on CareASSIST by Sanofi, including financial assistance, enrollment, and resource support for ELITEK patients. Contact a Care Manager. See Prescribing Info, including Boxed WARNING"
      />
      <Hero
      headline={<>Helping eligible patients access <span className="el-headline">ELITEK</span></>}
      copy="CareASSIST guides you through your ELITEK treatment journey, with a dedicated Care Manager to provide support every step of the way"
      subheadline="Talk to your doctor about enrolling today"
      hasButton={false}
      brandLogo={BrandLogo}
      brandAlt="Elitek Logo"
      btnLink="/elitek/enrollment"
      btnText=""
      btnClass="elitek"
      hasLogo={true}
      linkId="patient-elitek-talktoyourdoctor"
      // buttonIcon={RightArrow}
      />      
      <section className="content-section section-home-elitek" id="overview">

        <div className="flex-section evenly home-card-container">
          {/* Vertical Icon Card Examples - no extra class needed */}
          <LinkCard
          img={AffordIcon}
          alt="Financial Assistance Icon"          
          cardTitle={<>Financial<br/>assistance</>}
          cardText="Discover how much you may be able to save"
          cardLink=""
          cardClass="product-home elitek-home"
          linkID="patient-elitek-financial-assistance-link"
          linkImgID="financial-assistance-link-image"
          linkTitleID="financial-assistance-link-title"
          linkTxtID="financial-assistance-link-text"
          btnLink="/elitek/financial-assistance"
          btnClass="elitek"
          />
          <LinkCard
          img={enrollmentIcon}
          alt="Resource support icon"
          cardTitle={<>Resource<br/>support</>}
          cardText="Get connected to independent support organizations and resources"
          cardLink=""
          cardClass="product-home elitek-home"
          linkID="patient-elitek-resource-support-link"
          linkImgID="enrollment-link-image"
          linkTitleID="enrollment-link-title"
          linkTxtID="enrollment-link-text"
          btnLink="/elitek/resource-support"
          btnClass="elitek"
          />
          <LinkCard
          img={FAQIcon}
          alt="Frequently Asked Questions Icon"
          cardTitle="Frequently asked questions"
          cardText="Your questions, answered"
          cardLink=""
          cardClass="product-home elitek-home"
          linkID="patient-elitek-faq-link"
          linkImgID="faq-link-image"
          linkTitleID="faq-link-title"
          linkTxtID="faq-link-text"
          btnLink="/elitek/faqs"
          btnClass="elitek"
          />
        </div>
        <p className="pi-paragraph">Please see full <a id="patient-elitek-pi" href="https://products.sanofi.us/elitek/elitek.html" target="_blank" rel="noreferrer">Prescribing Information</a>, including Boxed WARNING.</p>
        <HaveQuestions 
        icon={QuestionsIcon}
        />
      </section>
      <div className="back-to-top-container">
        <BackToTop/>
      </div>
    </Layout>
    
)

export default pageContent